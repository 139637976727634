import { Stack, styled, Typography } from '@mui/material'
import { Button } from 'components/common/Button'

export const PageWrapper = styled(Stack)({
  backgroundColor: '#f1f1f1',
  width: '100vw',
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  overflow: 'hidden',

  height: 'calc(var(--vh, 1vh) * 100)',

  '*': {
    boxSizing: 'border-box',
  },
})

export const GraphWrapper = styled(Stack)<{ graphsize: number }>(
  ({ graphsize }) => ({
    borderRadius: '0 0 30px 30px',
    width: '100%',
    transition: 'height 0.5s',

    height: graphsize ? `calc(${graphsize}px + 40px)` : '40px',

    canvas: {
      transition: 'height 0.5s',
    },
  })
)

export const ChangeGraphVisBtn = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '0 0 30px 30px',
  width: '100%',
  maxWidth: '100%',
  color: theme.palette.primary.main,

  ':hover': {
    backgroundColor: '##5578eb4d',
  },

  [theme.breakpoints.down('lg')]: {
    ':hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
}))

export const AccordionsWrapper = styled(Stack)<{ graphsize: number }>(
  ({ graphsize }) => ({
    overflowY: 'auto',
    marginTop: 16,
    height: `calc(var(--vh, 1vh) * 100 - ${graphsize}px)`,
    transition: 'height 0.5s',
  })
)

export const InnerWrapper = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  marginBottom: 10,
  flexWrap: 'wrap',

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const Record = styled(Stack)(({ theme }) => ({
  marginBottom: 12,
  width: '33%',
  textAlign: 'start',
  paddingLeft: '3%',

  b: {
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '18px',
    color: theme.palette.text.secondary,
    wordBreak: 'break-word',
  },

  span: {
    fontSize: 16,
    lineHeight: '24px',
    color: theme.palette.common.black,
    wordBreak: 'break-word',
  },

  [theme.breakpoints.down('md')]: {
    width: '50%',
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const RelationInfo = styled(Stack)({
  padding: '16px 0',
})

export const RelName = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontWeight: 500,
  textAlign: 'start',
}))

export const RelValue = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  textAlign: 'start',
  textDecoration: 'underline',
  marginRight: 8,
  wordBreak: 'break-word',
}))
